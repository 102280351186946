 import React from "react";
import './Keyboard.css';
import { useState } from "react";

export default function Keyboard({enterPressed}) {
  let [keyboardPage, setKeyboardPage] = useState('lowercase');
  let [lastShift, setLastShift] = useState(0);
  let [shiftLock, setShiftLock] = useState(false);


  function clickKey(e, key) {
    if (key === "send") {
      enterPressed();
      setKeyboardPage("lowercase");
      setShiftLock(false);
      return;
    }

    if (key === "shift") {
      if ((((new Date()) - lastShift) < 300) && keyboardPage === "uppercase") {
        setShiftLock(true);
        return
      }
      if (keyboardPage === "lowercase") {
        setKeyboardPage("uppercase");
      }

      if (keyboardPage === "uppercase") {
        setKeyboardPage("lowercase");
        setShiftLock(false);
      }

      
      setLastShift(new Date());
      return
    }

    if ((keyboardPage === "uppercase") && !shiftLock) {
      setKeyboardPage("lowercase");
    }

    document.getElementById('message-input').value = document.getElementById('message-input').value + key;
  }

  return (
    <div>
    { (keyboardPage === "lowercase")?
      <div class="keyboard lowercase">
        <div class="first row">
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "q")}}><span letter="q">q</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "w")}}><span letter="w">w</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "e")}}><span letter="e">e</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "r")}}><span letter="r">r</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "t")}}><span letter="t">t</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "y")}}><span letter="y">y</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "u")}}><span letter="u">u</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "i")}}><span letter="i">i</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "o")}}><span letter="o">o</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "p")}}><span letter="p">p</span></div>
        </div>
        <div class="second row">
          <div class="key zoom a" onTouchEnd={(e) => {clickKey(e, "a")}}><span letter="a">a</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "s")}}><span letter="s">s</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "d")}}><span letter="d">d</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "f")}}><span letter="f">f</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "g")}}><span letter="g">g</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "h")}}><span letter="h">h</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "j")}}><span letter="j">j</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "k")}}><span letter="k">k</span></div>
          <div class="key zoom l" onTouchEnd={(e) => {clickKey(e, "l")}}><span letter="l">l</span></div>
        </div>
        <div class="third row">
          <div class="key dark shift darken" onTouchEnd={(e) => {clickKey(e, "shift")}}><span /></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "z")}}><span letter="z">z</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "x")}}><span letter="x">x</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "c")}}><span letter="c">c</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "v")}}><span letter="v">v</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "b")}}><span letter="b">b</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "n")}}><span letter="n">n</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "m")}}><span letter="m">m</span></div>
          <div class="key dark backspace darken" onTouchEnd={(e) => {document.getElementById('message-input').value = document.getElementById('message-input').value.slice(0, -1)}}><span /></div>
        </div>
        <div class="fourth row">
          <div class="key dark numbers darken" onTouchEnd={(e) => {setKeyboardPage("numbers")}}><span>123</span></div>
          <div class="key dark emoji darken" onTouchEnd={(e) => {clickKey(e, "😂")}}><span>😂</span></div>
          <div class="key spacebar darken" onTouchEnd={(e) => {clickKey(e, " ")}}><span>space</span></div>
          <div class="key dark return darken" onTouchEnd={(e) => {clickKey(e, "send")}}><span>send</span></div>
        </div>
      </div>
      :""
    }
    { (keyboardPage === "uppercase")?
      <div class="keyboard uppercase">
        <div class="first row">
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "Q")}}><span letter="Q">Q</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "W")}}><span letter="W">W</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "E")}}><span letter="E">E</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "R")}}><span letter="R">R</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "T")}}><span letter="T">T</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "Y")}}><span letter="Y">Y</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "U")}}><span letter="U">U</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "I")}}><span letter="I">I</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "O")}}><span letter="O">O</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "P")}}><span letter="P">P</span></div>
        </div>
        <div class="second row">
          <div class="key zoom a" onTouchEnd={(e) => {clickKey(e, "A")}}><span letter="A">A</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "S")}}><span letter="S">S</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "D")}}><span letter="D">D</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "F")}}><span letter="F">F</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "G")}}><span letter="G">G</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "H")}}><span letter="H">H</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "J")}}><span letter="J">J</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "K")}}><span letter="K">K</span></div>
          <div class="key zoom l" onTouchEnd={(e) => {clickKey(e, "L")}}><span letter="L">L</span></div>
        </div>
        <div class="third row">
          <div class={`key darken ${shiftLock?"shift-lock":"shift-on"}`} onTouchEnd={(e) => {clickKey(e, "shift")}}><span /></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "Z")}}><span letter="Z">Z</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "X")}}><span letter="X">X</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "C")}}><span letter="C">C</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "V")}}><span letter="V">V</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "B")}}><span letter="B">B</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "N")}}><span letter="N">N</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "M")}}><span letter="M">M</span></div>
          <div class="key dark backspace darken" onTouchEnd={(e) => {document.getElementById('message-input').value = document.getElementById('message-input').value.slice(0, -1)}}><span /></div>
        </div>
        <div class="fourth row">
          <div class="key dark numbers darken" onTouchEnd={(e) => {setKeyboardPage("numbers")}}><span>123</span></div>
          <div class="key dark emoji darken" onTouchEnd={(e) => {clickKey(e, "😂")}}><span>😂</span></div>
          <div class="key spacebar darken" onTouchEnd={(e) => {clickKey(e, " ")}}><span>space</span></div>
          <div class="key dark return darken" onTouchEnd={(e) => {clickKey(e, "send")}}><span>send</span></div>
        </div>
      </div>
      :""
    }
    { (keyboardPage === "numbers")?
      <div class="keyboard numbers">
        <div class="first row">
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "1")}}><span letter="1">1</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "2")}}><span letter="2">2</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "3")}}><span letter="3">3</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "4")}}><span letter="4">4</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "5")}}><span letter="5">5</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "6")}}><span letter="6">6</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "7")}}><span letter="7">7</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "8")}}><span letter="8">8</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "9")}}><span letter="9">9</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "0")}}><span letter="0">0</span></div>
        </div>
        <div class="second row">
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "-")}}><span letter="-">-</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "/")}}><span letter="/">/</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, ":")}}><span letter=":">:</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, ";")}}><span letter=";">;</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "(")}}><span letter="(">(</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, ")")}}><span letter=")">)</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "£")}}><span letter="£">£</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "&")}}><span letter="&">&</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "@")}}><span letter="@">@</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, '"')}}><span letter='"'>"</span></div>
        </div>
        <div class="third row">
          <div class="key dark special darken" onTouchEnd={(e) => {setKeyboardPage("special")}}><span>#+=</span></div>
          <div style={{width: "14%"}} class="key zoom" onTouchEnd={(e) => {clickKey(e, ".")}}><span letter=".">.</span></div>
          <div style={{width: "14%"}} class="key zoom" onTouchEnd={(e) => {clickKey(e, ",")}}><span letter=",">,</span></div>
          <div style={{width: "14%"}} class="key zoom" onTouchEnd={(e) => {clickKey(e, "?")}}><span letter="?">?</span></div>
          <div style={{width: "14%"}} class="key zoom" onTouchEnd={(e) => {clickKey(e, "!")}}><span letter="!">!</span></div>
          <div style={{width: "14%"}} class="key zoom" onTouchEnd={(e) => {clickKey(e, "'")}}><span letter="'">'</span></div>
          <div class="key dark backspace darken" onTouchEnd={(e) => {document.getElementById('message-input').value = document.getElementById('message-input').value.slice(0, -1)}}><span /></div>
        </div>
        <div class="fourth row">
          <div class="key dark numbers darken" onTouchEnd={(e) => {setKeyboardPage("lowercase")}}><span>ABC</span></div>
          <div class="key dark emoji darken" onTouchEnd={(e) => {clickKey(e, "😂")}}><span>😂</span></div>
          <div class="key spacebar darken" onTouchEnd={(e) => {clickKey(e, " ")}}><span>space</span></div>
          <div class="key dark return darken" onTouchEnd={(e) => {clickKey(e, "send")}}><span>send</span></div>
        </div>
      </div>
      :""
    }
    { (keyboardPage === "special")?
      <div class="keyboard numbers">
        <div class="first row">
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "[")}}><span letter="[">[</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "]")}}><span letter="]">]</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "{")}}><span letter="{">{"{"}</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "}")}}><span letter="}">{"}"}</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "#")}}><span letter="#">#</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "%")}}><span letter="%">%</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "^")}}><span letter="^">^</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "*")}}><span letter="*">*</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "+")}}><span letter="+">+</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "=")}}><span letter="=">=</span></div>
        </div>
        <div class="second row">
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "_")}}><span letter="_">_</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "\\")}}><span letter="\">\</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "|")}}><span letter="|">|</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "~")}}><span letter="~">~</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "<")}}><span letter="<">{"<"}</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, ">")}}><span letter="?">{">"}</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "€")}}><span letter="€">€</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "$")}}><span letter="$">$</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "¥")}}><span letter="¥">¥</span></div>
          <div class="key zoom" onTouchEnd={(e) => {clickKey(e, "•")}}><span letter="•">•</span></div>
        </div>
        <div class="third row">
          <div class="key dark special darken" onTouchEnd={(e) => {setKeyboardPage("numbers")}}><span>123</span></div>
          <div style={{width: "14%"}} class="key zoom" onTouchEnd={(e) => {clickKey(e, ".")}}><span letter=".">.</span></div>
          <div style={{width: "14%"}} class="key zoom" onTouchEnd={(e) => {clickKey(e, ",")}}><span letter=",">,</span></div>
          <div style={{width: "14%"}} class="key zoom" onTouchEnd={(e) => {clickKey(e, "?")}}><span letter="?">?</span></div>
          <div style={{width: "14%"}} class="key zoom" onTouchEnd={(e) => {clickKey(e, "!")}}><span letter="!">!</span></div>
          <div style={{width: "14%"}} class="key zoom" onTouchEnd={(e) => {clickKey(e, "'")}}><span letter="'">'</span></div>
          <div class="key dark backspace lighten" onTouchEnd={(e) => {document.getElementById('message-input').value = document.getElementById('message-input').value.slice(0, -1)}}><span /></div>
        </div>
        <div class="fourth row">
          <div class="key dark numbers darken" onTouchEnd={(e) => {setKeyboardPage("lowercase")}}><span>ABC</span></div>
          <div class="key dark emoji darken" onTouchEnd={(e) => {clickKey(e, "😂")}}><span>😂</span></div>
          <div class="key spacebar darken" onTouchEnd={(e) => {clickKey(e, " ")}}><span>space</span></div>
          <div class="key dark return darken" onTouchEnd={(e) => {clickKey(e, "send")}}><span>send</span></div>
        </div>
      </div>
      :""
    }
    </div>
  );
}
