import React from "react";
import { useEffect, useState } from "react";
import './ChatListButton.css';

import profile_pic from '../images/profile_pic.png'


export default function ChatListButton(props) {

  const ref = React.createRef();

  let [swiping, setSwiping] = useState(false);


  useEffect(() => {
    ref.current.addEventListener('touchstart', function(e){
        var touchobj = e.changedTouches[0]
        if (touchobj.pageX < 50) {
          setSwiping(true);

          document.getElementById("chat-root").style.transition="";
          ref.current.style.transition="";
        }

    }, false)

    ref.current.addEventListener('touchmove', function(e){
      var touchobj = e.changedTouches[0]
      if (swiping) {
        e.preventDefault() // prevent scrolling when inside DIV
        if (ref.current) {
          document.getElementById("chat-root").style.transform=`translateX(${touchobj.pageX}px)`;

          ref.current.style.transform=`translateX(${touchobj.pageX}px)`;
        }
      }
    }, false)

    ref.current.addEventListener('touchend', function(e){
        var touchobj = e.changedTouches[0]
        if (swiping) {
          if (touchobj.pageX < (window.innerWidth / 2)) {
            closeChat()
          } else {
            openChat()
          }
          setSwiping(false);
        }

    }, false)
  });

  function closeChat() {
    document.getElementById("chat-root").style.transition="ease transform 0.2s";
    document.getElementById("chat-root").style.transform=`translateX(0)`;

    ref.current.style.transition="ease transform 0.2s, ease background-color 0.2s";
    ref.current.style.transform=`translateX(0)`;
  }

  function openChat() {
    document.getElementById("chat-root").style.transition="ease transform 0.2s, ease background-color 0.2s";
    document.getElementById("chat-root").style.transform=`translateX(100vw)`;

    ref.current.style.transition="ease transform 0.2s";
    ref.current.style.transform=`translateX(0)`;
  }

  return (
    <div style={{overflow: "hidden"}}>
      <div class="chat_list_button" ref={ref} onClick={() => {openChat()}}>

          <img class="profile_pic" src={profile_pic} alt=""/>
          <h1 class="name">John Watkins</h1>
          <p class="last_message">Hello Joe</p>
      </div>
    </div>
  );
}
