import React from "react";
import { Link } from 'react-router-dom';
import './Login.css';

function Login() {
  return (
    <div>
      <div class="circle_background">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div class="login-view">
        <div class="login-box">
          <div class="animated_logo" style={{marginTop: "44px"}}>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <h2 class="brand-text" style={{margin:"6px 0"}}>bubl</h2>

          <div class="form-input" style={{marginTop: "24px"}}>
              <label for="username">Username</label>
              <input type="text" name="username" />
          </div>

          <div class="form-input" style={{marginTop: "20px"}}>
              <label for="password">Password</label>
              <input type="password" name="password" />
          </div>

          <Link class="form-btn" to="/home"style={{marginTop: "20px"}}>Log In</Link>

          <span class="login-box-register-button">Don’t have an account?&nbsp;<b>Sign up here.</b></span>
        </div>
      </div>
    </div>
  );
}

export default Login;
