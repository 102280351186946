import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { MemoryRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import ProtectedRoute from './utils/ProtectedRoute';
import WithBottomNav from './utils/WithBottomNav';
import WithoutBottomNav from './utils/WithoutBottomNav';

import App from './App';
import Login from './Login';
import PageOne from "./PageOne";
import PageTwo from "./PageTwo";


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <Routes>

      <Route element={<WithBottomNav />}>
        <Route path="/" element={<Navigate to="/home"/>} />
        <Route path="/home" element={<ProtectedRoute><App /></ProtectedRoute>} />
        <Route path="/pageone" element={<ProtectedRoute><PageOne /></ProtectedRoute>} />
        <Route path="/pagetwo" element={<ProtectedRoute><PageTwo /></ProtectedRoute>} />
      </Route>

      <Route element={<WithoutBottomNav />}>
        <Route path="/login" element={<Login />} />
      </Route>

    </Routes>
  </Router>
);
