import React from "react";
import { useEffect } from "react";
import './Header.css';

export default function Header() {
  useEffect(() => {
    document.getElementById("root").style.paddingTop = '70px';
    return () => {  
      document.getElementById("root").style.paddingTop = 0;
    }
  }, [])

  const headerRef = React.createRef();
  const animationRequestRef = React.useRef()


  const animate = () => {
    if (window.pageYOffset < 0) {
        headerRef.current.classList.remove('scroll')
        if (window.pageYOffset > -100) {
          headerRef.current.style.transform=(`scale(${0.1+(-window.pageYOffset)/1000})`)
        }
      } else if (window.pageYOffset === 0) {
        headerRef.current.classList.remove('scroll')
        headerRef.current.style.transform=(`scale(0.1)`)
      } else {
        headerRef.current.classList.add('scroll')
        headerRef.current.style.transform=(`scale(0.1)`)
      }


    animationRequestRef.current = requestAnimationFrame(animate);
  }
    
  useEffect(() => {
    animationRequestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationRequestRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

   
  return (
    <div class="header" ref={headerRef}>
      <span class="logo"/>
    </div>
  );
}
