import React from "react";
import { useEffect, useState } from "react";

import "./Chat.css"

import ChatHeader from "./components/ChatHeader";
import MessageBubble from "./components/MessageBubble";
import MessageSender from "./components/MessageSender";
import Keyboard from "./components/Keyboard";

export default function Chat() {
  const chatContainerRef = React.createRef();
  let [swiping, setSwiping] = useState(false);
  let [chats, setChats] = useState([
    {
      text: "Hey Joe",
      received: true
    },{
      text: "hey! you okay?",
      sent: true
    },{
      text: "Yeah just looking at some microprocessors",
      received: true
    },{
      text: "oh nice! what is it?",
      sent: true
    },{
      text: "The ATTiny 85",
      received: true
    },{
      text: "And it runs arduino!",
      received: true
    },{
      text: "that looks cool, tiny and cheap",
      sent: true
    }
  ]);

  function closeChat() {
    document.getElementById("chat-root").style.transition="ease transform 0.2s";
    document.getElementById("chat-root").style.transform=`translateX(0)`;
  }

  function openChat() {
    document.getElementById("chat-root").style.transition="ease transform 0.2s";
    document.getElementById("chat-root").style.transform=`translateX(100vw)`;
  }

  function sendMessage() {
    setChats([...chats, {text: document.getElementById("message-input").value, sent: true}])
    document.getElementById("message-input").value = "";
  }

  useEffect(() => {

    chatContainerRef.current.scrollTo(0, chatContainerRef.current.scrollHeight);

    chatContainerRef.current.addEventListener('touchstart', function(e){
        var touchobj = e.changedTouches[0]
        if (touchobj.pageX > (window.innerWidth - 50)) {
          setSwiping(true);

          document.getElementById("chat-root").style.transition="";
        }

    }, false)

    chatContainerRef.current.addEventListener('touchmove', function(e){
      var touchobj = e.changedTouches[0]
      if (swiping) {
        e.preventDefault() // prevent scrolling when inside DIV
        if (chatContainerRef.current) {
          document.getElementById("chat-root").style.transform=`translateX(${touchobj.pageX}px)`;

        }
      }
    }, false)

    chatContainerRef.current.addEventListener('touchend', function(e){
        var touchobj = e.changedTouches[0]
        if (swiping) {
          if (touchobj.pageX < (window.innerWidth *0.9)) {
            closeChat()
          } else {
            openChat()
          }
          setSwiping(false);
        }

    }, false)
  });

  return (
    <div>
      <ChatHeader />

      <div ref={chatContainerRef} class="chat-container keyboard-open">

        {chats.map((item, i) => {
          return <MessageBubble received={item.received} sent={item.sent} text={item.text}/>
        })}

      </div>

      <MessageSender />
      <Keyboard enterPressed={() => {sendMessage()}}/>
    </div>
  );
}
