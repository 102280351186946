import React from "react";
import { useEffect } from "react";
import { Link } from 'react-router-dom';
import './BottomNav.css';


export default function BottomNav() {
  useEffect(() => {
    document.getElementById("root").style.paddingBottom = '100px';
    return () => {  
      document.getElementById("root").style.paddingBottom = 0;
    }
  }, [])

  const buttons = [
    {
      name: "button_chats",
      iconClass: "ic-chat",
      route: "/home",
      active: true,
      ref: React.createRef()
    },

    {
      name: "button_friends",
      iconClass: "ic-friends",
      route: "/pageone",
      active: false,
      ref: React.createRef()
    },

    {
      name: "button_settings",
      iconClass: "ic-home",
      route: "/pagetwo",
      active: false,
      ref: React.createRef()
    }
  ];

  const bottomNavBorderRef = React.createRef();
  const bottomNavSelectedCircleRef = React.createRef();

  function moveBottomNavBorder(amount) {
    bottomNavBorderRef.current.style.transform = `translateX(${amount}px)`;
    bottomNavSelectedCircleRef.current.style.transform = `translateX(${amount}px)`;
  };

  function selectBottomNavButton(clicked_button) {
    const clicked_button_element = buttons[clicked_button].ref.current;

    const rect = clicked_button_element.getBoundingClientRect();
    const centerOfScreenToCentreOfButtonPressed = -((window.innerWidth/2) - ((rect.width / 2) + rect.left));

    moveBottomNavBorder(centerOfScreenToCentreOfButtonPressed);

    for (let button of buttons) {
      button.ref.current.classList.remove("active");
    }
    clicked_button_element.classList.add("active");
  }
  
  return (
    <div class="bottom-nav">

      <span class="bottom-nav-border" ref={bottomNavBorderRef}></span>
      <span class="bottom-nav-selected-circle" ref={bottomNavSelectedCircleRef}></span>

      {buttons.map((value, index) => {
        return (
        <Link to={value.route} className="bottom-nav-button" key={index} ref={value.ref} onClick={() => {selectBottomNavButton(index)}}>

            <span class={"bottom-nav-icon glyph " + value.iconClass}></span>

        </Link>
        )
      })}

    </div>
  );
}
