import React from "react";
import { useState } from "react";
import ReactDom from 'react-dom';
import ChatListButton from "./components/ChatListButton"
import ChatListSeperator from "./components/ChatListSeperator"


import Chat from "./Chat";

export default function PageOne() {
  let [selectedChatListButtonRef, setSelectedChatListButtonRef] = useState(0);
  
  return (
    <div>
      {ReactDom.createPortal(<Chat selectedChatListButtonRef={selectedChatListButtonRef}/>,document.getElementById('chat-root'))}

      <ChatListButton setSelectedChatListButtonRef={setSelectedChatListButtonRef}/>
      <ChatListSeperator />
      <ChatListButton setSelectedChatListButtonRef={setSelectedChatListButtonRef}/>
      <ChatListSeperator />
      
      
    </div>  
  );
}
