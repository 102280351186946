import React from "react";
import './ChatHeader.css';

import profile_pic from '../images/profile_pic.png'

export default function ChatHeader() {
  function closeChat() {
    document.getElementById("chat-root").style.transition="ease transform 0.2s";
    document.getElementById("chat-root").style.transform=`translateX(0)`;
  }

  return (
    <div class="chat-header">
      <img class="profile_pic" src={profile_pic} alt="" />
      <h1 class="name">John Watkins</h1>
      <p class="online_status">Online</p>
      <div class="back-arrow" onClick={() => {closeChat()}}>
        <span />
      </div>
    </div>
  );
}
