import React from 'react';
import BottomNav from '../components/BottomNav';
import Header from '../components/Header';
import { Outlet } from 'react-router';

const WithBottomNav = () => {
  return (
    <>
      <Header />
      <BottomNav />
      <Outlet />
    </>
  );
};

export default WithBottomNav;