import React from "react";
import './MessageBubble.css';

export default function MessageBubble({text, sent, received}) {
  return (
    <div class={`message-bubble-container ${sent ? "sent":""} ${received ? "received":""}`}>
      <span>{text}</span>
    </div>
  );
}
