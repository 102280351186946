import React from "react";
import './MessageSender.css';


export default function MessageSender() {
  return (
    <div class="message-sender open">
      <input id="message-input" type="text" placeholder="Message" readonly="readonly"/>
      <button class="send">Send</button>
    </div>
  );
}
